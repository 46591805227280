import type { FC } from 'react'
import { useCallback, useMemo } from 'react'
import type { StackProps } from '@chakra-ui/react'
import { Box, Button, HStack, MenuList } from '@chakra-ui/react'
import { GrandText } from 'ui'
import { AiFillFilter } from 'react-icons/ai'
import { useDispatch } from 'shared-redux'
import type { ProductsState } from 'shared-redux/state'
import { setSelectedPriceRange, setSelectedSorting, toggleFilterBrand } from 'shared-redux/state'
import { commaSeparator, createCn } from 'shared-utils'
import type { SortingOption } from 'ecosystem'
import { overrideText } from 'ui/lib/overrides'
import { useOverridesContext } from 'ui/lib/overrides/hooks'
import { Currency } from 'ecosystem'
import { clsx } from 'clsx'
import { useUpdateSearch } from '../../../hooks'
import { FILTERS_BEFORE_SHOW_MORE } from '../../../constants'
import FilterRangeSelector from '../../FilterRangeSelector'
import FilterMenus from '../../FilterMenus'
import FilterItems from './FilterItems'
import FilterSorting from './FilterSorting'
import FilterBrands from './FilterBrands'
import { filterButtonProps } from './filterButtonProps'

export interface IProductsFilterProps {
  handleMoreButton: () => void
  productsState: ProductsState
  sliderMaxPrice: number
  sliderDefaultValues: [number, number]
  filtersBeforeShowMore?: number
  sortingOptions: SortingOption[]
  wrapperProps?: StackProps
}

export interface ProductsFilterOverrides {
  prisFilterTitle?: string
  brandsTitle?: string
  sortAndFilter?: string
  currency?: string
  reset?: string
  search?: string
  sort?: string
  showMoreFilters?: string
}

const cn = createCn('product-filter')

const ProductsFilter: FC<IProductsFilterProps> = ({
  handleMoreButton,
  wrapperProps: { className, ...wrapperProps } = {},
  productsState,
  sortingOptions,
  sliderMaxPrice,
  sliderDefaultValues,
  filtersBeforeShowMore = FILTERS_BEFORE_SHOW_MORE
}) => {
  const {
    priceRangeFilter: priceRange,
    sortBy,
    sortOrder,
    sortLabel,
    selectedFilterBrandIds: selectedBrandIds,
    availableFilters,
    brandFilterOptions
  } = productsState
  const dispatch = useDispatch()
  const { updateSearch, clearFilters } = useUpdateSearch(
    productsState,
    sliderDefaultValues,
    sortingOptions
  )
  const overrides = useOverridesContext<keyof ProductsFilterOverrides>()

  const showFiltersCount = useMemo(() => {
    // price and brands filters are always shown, if available
    const defaultFiltersCount = brandFilterOptions.length ? 2 : 1
    return filtersBeforeShowMore - defaultFiltersCount
  }, [brandFilterOptions.length, filtersBeforeShowMore])

  const showMoreButton = useMemo(() => {
    return availableFilters.length > showFiltersCount
  }, [availableFilters, showFiltersCount])

  const handleSorting = useCallback(
    (selection: SortingOption) => {
      dispatch(setSelectedSorting(selection))
    },
    [dispatch]
  )

  const handleBrands = useCallback(
    (brandId: string) => {
      dispatch(toggleFilterBrand({ brandId }))
    },
    [dispatch]
  )

  return (
    <>
      <HStack
        align="flex-start"
        className={clsx(cn(), className)}
        display={{ base: 'none', md: 'flex' }}
        justify="flex-start"
        spacing={2}
        w="auto"
        wrap="wrap"
        {...wrapperProps}>
        <HStack className={cn('items-menu-wrapper')} flexGrow={0} mb={2} spacing={2}>
          {Boolean(brandFilterOptions.length) && (
            <FilterMenus
              filterName={overrideText('Varumärken', overrides?.brandsTitle)}
              list={
                <MenuList zIndex={9999}>
                  <FilterBrands
                    onChange={handleBrands}
                    {...{ selectedBrandIds, brandFilterOptions }}
                  />
                </MenuList>
              }
              menuButtonProps={{
                className: clsx(cn('item-menu', { brand: true }))
              }}
            />
          )}

          {availableFilters.map(
            (filter, index) =>
              index <= showFiltersCount - 1 && (
                <FilterMenus
                  filterName={filter.name}
                  // eslint-disable-next-line react/no-array-index-key -- No available ID
                  key={`${filter.name}-${index}`}
                  list={
                    <MenuList zIndex={9999}>
                      <Box
                        minWidth={{
                          base: 200,
                          md: 500
                        }}>
                        <FilterItems
                          {...{ filter, sortingOptions, productsState, sliderMaxPrice }}
                        />
                      </Box>
                    </MenuList>
                  }
                  menuButtonProps={{
                    className: clsx(cn('item-menu', { [filter.name]: true }))
                  }}
                />
              )
          )}

          <FilterMenus
            filterName={overrideText('Pris', overrides?.prisFilterTitle)}
            list={
              <MenuList zIndex={9999}>
                <FilterRangeSelector
                  currentMaxValue={commaSeparator(priceRange[1])}
                  currentMinValue={commaSeparator(priceRange[0])}
                  rangeProps={{
                    minWidth: {
                      base: 200,
                      md: 380
                    },
                    value: priceRange,
                    defaultValue: sliderDefaultValues,
                    onChange: (values) =>
                      dispatch(setSelectedPriceRange(values as [number, number]))
                  }}
                  unitsLabel={overrideText(Currency.SEK, overrides?.currency)}
                  {...{ sliderMaxPrice }}
                />
              </MenuList>
            }
            menuButtonProps={{
              className: clsx(cn('item-menu', { price: true }))
            }}
          />

          {showMoreButton ? (
            <Button
              className={cn('show-more-filters')}
              {...filterButtonProps}
              minH="35px"
              onClick={handleMoreButton}>
              <HStack>
                <AiFillFilter />
                <GrandText>
                  {overrideText('Visa fler filter', overrides?.showMoreFilters)}
                </GrandText>
              </HStack>
            </Button>
          ) : null}
        </HStack>

        <FilterMenus
          filterName={overrideText('Sortera', overrides?.sort)}
          list={
            <MenuList zIndex={9999}>
              <FilterSorting
                onChange={handleSorting}
                {...{ selectedSorting: { sortBy, sortOrder, sortLabel }, sortingOptions }}
              />
            </MenuList>
          }
          menuButtonProps={{
            border: '1px solid',
            borderColor: 'primary',
            className: cn('sorting')
          }}
        />
        <Button
          className={cn('update-search')}
          minH="35px"
          minW={20}
          onClick={() => {
            updateSearch()
          }}
          size="sm">
          {overrideText('Sök', overrides?.search)}
        </Button>

        <Button
          className={cn('clear-filters')}
          minH="35px"
          onClick={() => clearFilters()}
          size="sm">
          {overrideText('Rensa all filtrering', overrides?.reset)}
        </Button>
      </HStack>
      <Button
        aria-label="show-sorting-and-filters"
        className={cn('show-sorting-and-filters')}
        display={{ base: 'block', md: 'none' }}
        minH="35px"
        minW={100}
        onClick={handleMoreButton}
        size="sm"
        w={{ base: 'full', sm: 'auto' }}>
        <HStack justify="center" w="full">
          <AiFillFilter />
          <Box>{overrideText('Sortera och filtrera', overrides?.sortAndFilter)}</Box>
        </HStack>
      </Button>
    </>
  )
}

export default ProductsFilter
