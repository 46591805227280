'use client'
import type { FC } from 'react'
import { useEffect, useMemo, useState } from 'react'
import { HStack, VStack } from '@chakra-ui/react'
import type { AddToCartPayload, ProductPrice } from 'ecosystem'
import { overrideText } from 'ui/lib/overrides'
import { useOverridesContext } from 'ui/lib/overrides/hooks'
import { useProductContext } from '../hooks/useProductContext'
import { IngridPDPWidget } from '../../delivery'
import ProductDetailDeliveryLabel from './ProductDetailDeliveryLabel'
import ProductDetailAddToCartButton from './ProductDetailAddToCartButton'
import ProductDetailAddToCartCounter from './ProductDetailAddToCartCounter'

interface IProductDeliveryStateProps {
  onAddToCart: (payload: AddToCartPayload) => void
  isDisabled?: boolean
  totalPrices: ProductPrice
}

export interface ProductDetailAddToCartOverrides {
  updateCart?: string
  addToCart?: string
}

const ProductDetailAddToCart: FC<IProductDeliveryStateProps> = ({
  isDisabled,
  onAddToCart,
  totalPrices
}) => {
  const { product, productDelivery, cartItems, locale, shippingConfig } = useProductContext()
  const [qty, setQty] = useState('1')
  const overrides = useOverridesContext<keyof ProductDetailAddToCartOverrides>()
  const shippingProvider = shippingConfig?.provider

  useEffect(() => {
    // this will update the qty everythime the product changes
    setQty('1')
  }, [product])

  const isProductInCart = useMemo(() => {
    return Boolean(cartItems.find((item) => item.id === product.id))
  }, [cartItems, product.id])

  const btnLabel = isProductInCart
    ? overrideText('Uppdatera varukorg', overrides?.updateCart)
    : overrideText('Lägg till i varukorg', overrides?.addToCart)

  const renderShippingProviderDetails = () => {
    if (!shippingProvider) {
      return null
    }

    switch (shippingProvider) {
      case 'INGRID':
        return (
          <IngridPDPWidget
            config={{
              locale: locale ?? 'sv'
            }}
            product={product}
            quantity={Number(qty)}
            totalPrice={totalPrices.price}
          />
        )
      case 'DEFAULT':
        return null
    }
  }

  const isShippingProviderDetailsVisible =
    typeof shippingProvider === 'string' &&
    shippingProvider !== 'DEFAULT' &&
    productDelivery?.buyable
  const isButtonDisabled = !productDelivery?.buyable || !product.price || isDisabled

  return (
    <VStack alignItems="stretch" w="100%">
      <HStack align="flex-start" w="100%">
        <ProductDetailAddToCartCounter
          isDisabled={isButtonDisabled}
          onChange={(e) => {
            setQty(e)
          }}
          value={parseInt(qty)}
        />

        <VStack align="flex-start" flex={4}>
          <ProductDetailAddToCartButton
            aria-label={btnLabel}
            isDisabled={isButtonDisabled}
            onClick={() => {
              onAddToCart({ product, qty })
            }}>
            {btnLabel}
          </ProductDetailAddToCartButton>

          {!isShippingProviderDetailsVisible && (
            <ProductDetailDeliveryLabel label={productDelivery?.label} />
          )}
        </VStack>
      </HStack>

      {isShippingProviderDetailsVisible && renderShippingProviderDetails()}
    </VStack>
  )
}

export default ProductDetailAddToCart
