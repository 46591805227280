import { useContext } from 'react'
import type { ProductContextType } from '../context'
import { ProductContext } from '../context'

export const useProductContext = (): ProductContextType => {
  const ctx = useContext(ProductContext)

  if (!ctx) {
    throw Error('No context provider was found. Wrap your app with the ProductContextProvider')
  }

  return { ...ctx }
}
