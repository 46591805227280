'use client'
import type { ParsedUrlQuery } from 'node:querystring'
import { useDispatch } from 'shared-redux'
import type { ChangeEvent } from 'react'
import { useMemo, useCallback, useEffect } from 'react'
import { useSearchParams } from 'next/navigation'
import type { FilterOptionDTO, SortingOption } from 'ecosystem'
import { FilterType } from 'ecosystem'
import type { ProductsState } from 'shared-redux/state'
import {
  removeSelectedFilterNumericValue,
  setSelectedFilterBrandIds,
  setSelectedFilterCheckboxes,
  setSelectedFilterNumericValues,
  setSelectedPriceRange,
  setSelectedSorting
} from 'shared-redux/state'
import { filterValuesFactory, getFilterType } from 'api'

interface UseFiltersProps {
  productsState: ProductsState
  sortingOptions: SortingOption[]
  filter: FilterOptionDTO
}

const useFilters = ({ productsState, sortingOptions, filter }: UseFiltersProps) => {
  const dispatch = useDispatch()
  const {
    selectedFilterCheckboxes: selectedCheckboxes,
    selectedFilterNumericValues: selectedNumericValues
  } = productsState
  const filterType = getFilterType(filter)

  const handleTextValues = useCallback(
    (values: string[]) => {
      values.forEach((v) => {
        dispatch(
          setSelectedFilterCheckboxes({
            checkboxName: v,
            isChecked: true,
            checkboxId: filter.name
          })
        )
      })
    },
    [dispatch, filter.name]
  )

  const handleSliders = useCallback(
    (values: [number, number]) => {
      dispatch(
        setSelectedFilterNumericValues({
          id: filter.name,
          range: values
        })
      )
    },
    [dispatch, filter.name]
  )

  const setFilterComponentValues = useCallback(
    (filterQPs: ParsedUrlQuery) => {
      for (const filterKey in filterQPs) {
        if (filterKey.toLowerCase() === filter.name.toLowerCase()) {
          const values = filterValuesFactory(filterQPs, filterKey)

          switch (filterType) {
            case FilterType.TEXT:
              handleTextValues(values)
              break
            case FilterType.NUMBER:
              handleSliders(values.map((v) => parseInt(v)).slice(0, 2) as [number, number])
              break
            default:
              break
          }
        }
      }
    },
    [filter.name, filterType, handleSliders, handleTextValues]
  )

  const searchParams = useSearchParams()
  const paramsObject = useMemo(
    () => (searchParams?.entries() ? Object.fromEntries(searchParams.entries()) : {}),
    [searchParams]
  )

  /** recover filter data from the route qs and update the state */
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars -- We want to destructure slug
    const { minPrice, maxPrice, sortBy, sortOrder, slug, brandIds, ...restOfQueryParams } =
      paramsObject
    if (minPrice && maxPrice) {
      dispatch(setSelectedPriceRange([parseInt(minPrice), parseInt(maxPrice)]))
    }
    if (sortBy && sortOrder && (sortOrder === 'ASC' || sortOrder === 'DESC')) {
      const sortLabel = sortingOptions.find(
        (o) => o.sortBy === sortBy && o.sortOrder === sortOrder
      )?.sortLabel
      dispatch(setSelectedSorting({ sortBy, sortOrder, sortLabel: sortLabel || '' }))
    }
    if (brandIds?.length) {
      dispatch(
        setSelectedFilterBrandIds({ brandIds: Array.isArray(brandIds) ? brandIds : [brandIds] })
      )
    }

    setFilterComponentValues(restOfQueryParams)
  }, [dispatch, paramsObject, setFilterComponentValues, sortingOptions])

  const handleCheckBoxes = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      dispatch(
        setSelectedFilterCheckboxes({
          checkboxName: event.target.name,
          isChecked: event.target.checked,
          checkboxId: event.target.id
        })
      )
    },
    [dispatch]
  )

  const handleRemoveSlider = useCallback(
    (id: string) => {
      dispatch(removeSelectedFilterNumericValue(id))
    },
    [dispatch]
  )

  return {
    handleCheckBoxes,
    handleSliders,
    selectedCheckboxes,
    selectedNumericValues,
    handleRemoveSlider,
    filterType
  }
}

export default useFilters
