'use client'
import { Text, VStack } from '@chakra-ui/react'
import { GrandLink } from 'ui'
import type { ReactNode } from 'react'

interface ProductAttachedItemProps {
  name: ReactNode
  url: string
  icon: ReactNode
}

export const ProductAttachedItem = ({ name, url, icon }: ProductAttachedItemProps) => {
  return (
    <VStack
      as={GrandLink}
      border="1px"
      borderColor="background.surface"
      borderRadius={14}
      color="grey"
      download
      href={url}
      isExternal
      p={4}>
      {icon}

      <Text cursor="inherit" fontSize={12} pt={2} textAlign="center">
        {name}
      </Text>
    </VStack>
  )
}
