import { VStack } from '@chakra-ui/react'
import { createCn } from 'shared-utils/utils'
import { useProductContext } from '../hooks/useProductContext'
import BundleItem from './BundleItem'

const cn = createCn('product-bundles')

const ProductBundles = () => {
  const { bundles } = useProductContext()

  if (!bundles?.length) {
    return null
  }

  return (
    <VStack className={cn()}>
      {bundles.map((bundleItem) => (
        <BundleItem {...{ bundleItem }} key={bundleItem.id} />
      ))}
    </VStack>
  )
}

export default ProductBundles
