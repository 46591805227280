'use client'
import type { FC } from 'react'
import { useState } from 'react'
import type { IGrandSection } from 'ui'
import { Carousel, GrandSection } from 'ui'
import type { DeliveryDTO, ProductLightDTO } from 'ecosystem'
import type { BoxProps } from '@chakra-ui/react'
import ProductListItem from './ProductListItem'

interface IFeaturedProducts extends IGrandSection {
  items: ProductLightDTO[]
  onBuy: (item: ProductLightDTO) => void
  onProductRequest: (item: ProductLightDTO) => void
  productsUrlPath: string
  deliveryOptions: DeliveryDTO[] | null
  title?: string
  headingTag?: any
  itemContainerProps?: Omit<BoxProps, 'gap' | 'children'>
}

const FeaturedProducts: FC<IFeaturedProducts> = ({
  deliveryOptions,
  title,
  items,
  headingTag,
  onBuy,
  onProductRequest,
  productsUrlPath,
  itemContainerProps,
  ...props
}) => {
  const [activeItem, setActiveItem] = useState(0)

  if (!items.length) {
    return null
  }

  return (
    <GrandSection {...{ title, headingTag }} {...props}>
      <Carousel gap={20} {...{ activeItem, setActiveItem, itemContainerProps }}>
        {items.map((item) => (
          <ProductListItem
            showsPrice
            key={item.id}
            w="full"
            {...{ item, deliveryOptions, onBuy, onProductRequest, productsUrlPath }}
          />
        ))}
      </Carousel>
    </GrandSection>
  )
}

export default FeaturedProducts
