import type { FC } from 'react'
import { useMemo } from 'react'
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Flex,
  VStack
} from '@chakra-ui/react'
import { MdOutlineFilterList } from 'react-icons/md'
import groupBy from 'lodash/groupBy'
import type { RelatedProductItem } from 'ecosystem'
import { deliveryFactory } from 'api'
import { useProductContext } from '../hooks/useProductContext'
import RelatedCartItem from './RelatedCartItem'

interface ProductSubProductsProps {
  subProducts: RelatedProductItem[]
}

/** generates an accordion group containing related p with "CUSTOM" type */
const ProductSubProducts: FC<ProductSubProductsProps> = ({ subProducts }) => {
  const { onAddToCart, deliveryOptions } = useProductContext()

  const subProductGroups = useMemo<Record<string, RelatedProductItem[]>>(
    () => groupBy(subProducts, 'customRelationType'),
    [subProducts]
  )

  if (!subProducts.length) {
    return null
  }

  return (
    <Accordion allowToggle className="ProductSubProducts" w="100%">
      {Object.keys(subProductGroups).map((customRelationType) => (
        <AccordionItem borderTop="none" key={customRelationType}>
          <AccordionButton _focus={{ outline: 'none' }} aria-label="related" px={0}>
            <Flex
              align="center"
              color="text.default"
              fontSize="sm"
              fontWeight="bold"
              justify="flex-start"
              textTransform="uppercase"
              w="100%">
              <MdOutlineFilterList />
            </Flex>
          </AccordionButton>
          <AccordionPanel borderColor="gray.200" borderTop="1px solid">
            {subProductGroups[customRelationType].map((r) => {
              const delivery = deliveryFactory(deliveryOptions, r.product.deliveryId)
              return (
                <VStack fontSize="xs" key={r.product.id} py={2} spacing={1} w="100%">
                  <RelatedCartItem
                    cartItem={r.product}
                    isEditable
                    isNew
                    isNotAvailable={!delivery?.buyable}
                    isNotAvailableText={delivery?.label}
                    key={r.related.relatedProductId}
                    onAddToCart={(payload) => {
                      onAddToCart({ ...payload.product, qty: payload.qty })
                    }}
                  />
                </VStack>
              )
            })}
          </AccordionPanel>
        </AccordionItem>
      ))}
    </Accordion>
  )
}

export default ProductSubProducts
