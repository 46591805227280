'use client'
import type { FC } from 'react'
import type { FlexProps } from '@chakra-ui/react'
import { Box, Flex, HStack } from '@chakra-ui/react'
import { GrandText } from 'ui'
import type { OldProductDTO } from 'ecosystem'
import { overrideText } from 'ui/lib/overrides'
import { useOverridesContext } from 'ui/lib/overrides/hooks'

interface IProductSpecificationProps extends FlexProps {
  product: OldProductDTO
}

export interface ProductSpecificationOverrides {
  productNotActive?: string
  articleNumber?: string
  gtin?: string
  brand?: string
  stockLevel?: string
  unit?: string
}

function Row({ label, value }: { label: string; value: string }) {
  return (
    <HStack pb={5} w={{ base: '100%', sm: '50%' }}>
      <Box flex={1}>
        <GrandText fontWeight="bold">{label}</GrandText>
      </Box>
      <Box flex={1}>
        <GrandText>{value}</GrandText>
      </Box>
    </HStack>
  )
}

const ProductSpecification: FC<IProductSpecificationProps> = ({ product, ...props }) => {
  const overrides = useOverridesContext<keyof ProductSpecificationOverrides>()
  if (!product.active) {
    return (
      <GrandText>
        {overrideText('Denna produkt är inte aktiv', overrides?.productNotActive)}
      </GrandText>
    )
  }

  return (
    <Flex fontSize={{ base: '2xs', md: 'sm' }} w="100%" wrap="wrap" {...props}>
      <Row
        label={overrideText('Artikelnummer', overrides?.articleNumber)}
        value={product.articleNumber}
      />
      {product.gtin ? (
        <Row label={overrideText('GTIN', overrides?.gtin)} value={product.gtin} />
      ) : null}
      {product.brand ? (
        <Row label={overrideText('Varumärke', overrides?.brand)} value={product.brand.name} />
      ) : null}
      {product.specifications?.map((specification) => {
        if (!specification.value) return null
        if (specification.hidden) return null
        return (
          <Row
            key={`specification${specification.value}${specification.name}`}
            label={specification.name}
            value={specification.value}
          />
        )
      })}
    </Flex>
  )
}

export default ProductSpecification
