import dynamic from 'next/dynamic'

const RegularProductInformation = dynamic(() => import('./RegularProductInformation'), {
  ssr: true
})

const RegularProducts = () => {
  return <RegularProductInformation />
}

export default RegularProducts
