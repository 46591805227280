'use client'
import { ModalLib, SlideOutLib } from 'ui'
import { SlideOutProvider, SlideOutStoreController, useContextSlideOut } from 'ui/lib/slideout'
import { ModalProvider, useContextModal } from 'ui/lib/modal'
import { SlideOutPayloadProps } from 'ui/lib/slideout/types'

const { SlideOutContext } = SlideOutLib.SlideOutContextProvider<
  SlideOutComponentType,
  SlideOutFooterType
>()

export type CustomSlideOutPayloadProps<T> = SlideOutPayloadProps<
  SlideOutComponentType,
  SlideOutFooterType,
  T
>

const { ModalContext } = ModalLib.ModalContextProvider<ModalType>()

export const useSlideOut = () => {
  return useContextSlideOut<SlideOutComponentType, SlideOutFooterType>(SlideOutContext)
}

export const useModal = () => {
  return useContextModal<ModalType>(ModalContext)
}

export { SlideOutContext, SlideOutProvider, SlideOutStoreController, ModalContext, ModalProvider }
