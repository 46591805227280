import type { FC } from 'react'
import { VStack } from '@chakra-ui/react'
import type { BrandLight } from 'ecosystem'
import CheckboxFilterItem from '../../CheckboxFilterItem'

interface IFilterBrandsProps {
  selectedBrandIds: string[]
  brandFilterOptions: BrandLight[]
  onChange: (brandId: string) => void
}

const FilterBrands: FC<IFilterBrandsProps> = ({
  onChange,
  selectedBrandIds,
  brandFilterOptions
}) => {
  return (
    <VStack>
      {brandFilterOptions.map((option) => {
        return (
          <CheckboxFilterItem
            id={option.id}
            isChecked={selectedBrandIds.includes(option.id)}
            key={option.id}
            name={option.name}
            onChange={(e) => {
              onChange(e.target.id)
            }}
            value={option.name}
          />
        )
      })}
    </VStack>
  )
}

export default FilterBrands
