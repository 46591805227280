import type { FC } from 'react'
import type { RangeSliderProps } from '@chakra-ui/react'
import {
  Box,
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  RangeSliderTrack
} from '@chakra-ui/react'
import { BiCircle } from 'react-icons/bi'

type IRangeFilterItemProps = RangeSliderProps

const RangeFilterItem: FC<IRangeFilterItemProps> = ({ ...props }) => {
  return (
    <RangeSlider
      // eslint-disable-next-line jsx-a11y/aria-proptypes -- Chakra types required
      aria-label={['min', 'max']}
      flexGrow={1}
      focusThumbOnChange={false}
      step={50}
      {...props}>
      <RangeSliderTrack bg="brand.darkExtraLight">
        <RangeSliderFilledTrack bg="background.overlay" />
      </RangeSliderTrack>
      <RangeSliderThumb bg="primary" boxSize={6} index={0}>
        <Box as={BiCircle} color="text.light" />
      </RangeSliderThumb>
      <RangeSliderThumb bg="primary" boxSize={6} index={1}>
        <Box as={BiCircle} color="text.light" />
      </RangeSliderThumb>
    </RangeSlider>
  )
}

export default RangeFilterItem
