import type { PropsWithChildren } from 'react'
import type { GridProps } from '@chakra-ui/react'
import { Grid } from '@chakra-ui/react'

type ProductAttachedItemsWrapperProps = PropsWithChildren<GridProps>

export const ProductAttachedItemsWrapper = ({
  children,
  ...props
}: ProductAttachedItemsWrapperProps) => {
  return (
    <Grid gap={2} gridTemplateColumns="repeat(auto-fit, minmax(115px, 13%))" w="100%" {...props}>
      {children}
    </Grid>
  )
}
