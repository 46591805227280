'use client'
import type { FC } from 'react'
import { Suspense } from 'react'
import {
  type CartItem,
  type ProductLightDTO,
  type SortingOption,
  type StorefrontWidget
} from 'ecosystem'
import type { ProductsState } from 'shared-redux/state'
import ProductListVertical from '../components/product-list-vertical/ProductListVertical'
import useProductList from '../hooks/useProductList'
import type { WidgetImplementation } from '../../widgets'
import type { ProductListItemProps } from '../components'

export interface ProductListVerticalWidgetType {
  name: string
}

type ProductListVerticalWidgetProps = WidgetImplementation<
  StorefrontWidget<ProductListVerticalWidgetType>
> & {
  productsState: ProductsState
  sortingOptions: SortingOption[]
  onAddToCart: (cartItem: CartItem) => void
  onMore: () => void
  onSearchUpdate: () => void
  sliderMaxPrice: number
  sliderDefaultValues: [number, number]
  onProductRequest: (item: ProductLightDTO) => void
  showsPrice: boolean
  paths: {
    getInitialProducts: string
    productsUrlPath: string
  }
  customComponents?: {
    ProductListItem?: FC<ProductListItemProps>
  }
}

const ProductListVerticalWidget: FC<ProductListVerticalWidgetProps> = ({
  productsState,
  sortingOptions,
  sliderMaxPrice,
  onAddToCart,
  onMore,
  onSearchUpdate,
  paths,
  onProductRequest,
  sliderDefaultValues,
  dynamicProps,
  customComponents,
  showsPrice
}) => {
  const { products, totalElements, loadMore, isLoading, isNextPageAvailable } = useProductList({
    id: dynamicProps?.viewId || '',
    type:
      (dynamicProps?.type.toLowerCase() as 'category' | 'brand' | 'tag' | undefined) || 'category',
    filterConfig: dynamicProps?.filterConfig || {
      brands: [],
      options: []
    },
    productsState,
    getInitialProducts: paths.getInitialProducts,
    sortingOptions
  })

  return (
    <ProductListVertical
      deliveryOptions={dynamicProps?.deliveryOptions ?? null}
      infiniteScrollConfig={{
        loadMore,
        shouldLoadMore: isNextPageAvailable
      }}
      items={products}
      productsUrlPath={paths.productsUrlPath}
      py={0}
      sectionId="product-list-1"
      title=""
      totalItems={totalElements}
      {...{
        isLoading,
        productsState,
        sortingOptions,
        onAddToCart,
        onMore,
        onSearchUpdate,
        sliderMaxPrice,
        onProductRequest,
        sliderDefaultValues,
        customComponents,
        showsPrice
      }}
    />
  )
}

// https://nextjs.org/docs/messages/missing-suspense-with-csr-bailout
const ProductListVerticalWidgetSuspended: FC<ProductListVerticalWidgetProps> = (props) => {
  const {
    productsState,
    sortingOptions,
    sliderMaxPrice,
    onAddToCart,
    onMore,
    onSearchUpdate,
    paths,
    onProductRequest,
    sliderDefaultValues,
    dynamicProps,
    customComponents,
    showsPrice
  } = props

  return (
    <Suspense
      fallback={
        <ProductListVertical
          deliveryOptions={dynamicProps?.deliveryOptions ?? null}
          infiniteScrollConfig={{
            loadMore: () => void 0,
            shouldLoadMore: false
          }}
          items={[]}
          productsUrlPath={paths.productsUrlPath}
          py={0}
          sectionId="product-list-1"
          title=""
          totalItems={0}
          {...{
            isLoading: true,
            productsState,
            sortingOptions,
            onAddToCart,
            onMore,
            onSearchUpdate,
            sliderMaxPrice,
            onProductRequest,
            sliderDefaultValues,
            customComponents,
            showsPrice
          }}
        />
      }>
      <ProductListVerticalWidget {...props} />
    </Suspense>
  )
}

export default ProductListVerticalWidgetSuspended
