import type { FC } from 'react'
import { useCallback, useMemo } from 'react'
import type { StackProps } from '@chakra-ui/react'
import { Button, HStack, Icon, Spacer, VStack } from '@chakra-ui/react'
import { BsSliders } from 'react-icons/bs'
import type { IAccordionItem } from 'ui'
import { GrandAccordion, GrandText } from 'ui'
import { useDispatch } from 'shared-redux'
import type { ProductsState } from 'shared-redux/state'
import { setSelectedPriceRange, toggleFilterBrand } from 'shared-redux/state'
import { commaSeparator } from 'shared-utils'
import type { SortingOption } from 'ecosystem'
import { overrideText } from 'ui/lib/overrides'
import { useOverridesContext } from 'ui/lib/overrides/hooks'
import { Currency } from 'ecosystem'
import { useUpdateSearch } from '../../hooks'
import FilterRangeSelector from '../FilterRangeSelector'
import FilterBrands from './ProductsFilter/FilterBrands'
import FilterItems from './ProductsFilter/FilterItems'

type IMoreFiltersProps = StackProps & {
  productsState: ProductsState
  onSearchUpdate: () => void
  sliderMaxPrice: number
  sliderDefaultValues: [number, number]
  sortingOptions: SortingOption[]
}

export interface MoreFiltersOverrides {
  brandsTitle?: string
  priceTitle?: string
  moreFiltersTitle?: string
  reset?: string
  cleanFilters?: string
  search?: string
  currency?: string
}

const MoreFilters: FC<IMoreFiltersProps> = ({
  onSearchUpdate,
  productsState,
  sliderMaxPrice,
  sliderDefaultValues,
  sortingOptions,
  ...props
}) => {
  const dispatch = useDispatch()
  const overrides = useOverridesContext<keyof MoreFiltersOverrides>()
  const {
    availableFilters,
    brandFilterOptions,
    selectedFilterBrandIds: selectedBrandIds,
    priceRangeFilter: priceRange
  } = productsState

  // ********************** VIEW HANDLERS ***********************

  const handleBrands = useCallback(
    (brandId: string) => {
      dispatch(toggleFilterBrand({ brandId }))
    },
    [dispatch]
  )
  const { updateSearch, isLoading, clearFilters } = useUpdateSearch(
    productsState,
    sliderDefaultValues,
    sortingOptions
  )

  const handleUpdateSearch = () => {
    updateSearch()
    onSearchUpdate()
  }

  // ********************** VIEW CONFIG ***********************
  const accordionItems: IAccordionItem[] = useMemo(() => {
    const filters = availableFilters.map((filter) => ({
      id: filter.name,
      title: filter.name,
      component: (
        <FilterItems
          {...{
            filter,
            sortingOptions,
            productsState,
            sliderMaxPrice
          }}
        />
      )
    }))

    return [
      {
        id: 'brands',
        title: overrideText('Varumärken', overrides?.brandsTitle),
        component: (
          <FilterBrands onChange={handleBrands} {...{ selectedBrandIds, brandFilterOptions }} />
        )
      },
      ...filters,
      {
        id: 'price',
        title: overrideText('Pris', overrides?.priceTitle),
        component: (
          <FilterRangeSelector
            currentMaxValue={commaSeparator(priceRange[1])}
            currentMinValue={commaSeparator(priceRange[0])}
            px={0}
            rangeProps={{
              value: priceRange,
              defaultValue: sliderDefaultValues,
              onChange: (values) => dispatch(setSelectedPriceRange(values as [number, number]))
            }}
            unitsLabel={overrideText(Currency.SEK, overrides?.currency)}
            w="full"
            {...{ sliderMaxPrice }}
          />
        )
      }
    ]
  }, [
    availableFilters,
    brandFilterOptions,
    dispatch,
    handleBrands,
    overrides?.brandsTitle,
    overrides?.currency,
    overrides?.priceTitle,
    priceRange,
    productsState,
    selectedBrandIds,
    sliderDefaultValues,
    sliderMaxPrice,
    sortingOptions
  ])

  return (
    <VStack h="full" justify="space-between" spacing={10} w="full" {...props}>
      <VStack spacing={6} w="full">
        <HStack justify="flex-end" px={3} w="full">
          <HStack spacing={2}>
            <Icon as={BsSliders} />
            <GrandText>{overrideText('Filter', overrides?.moreFiltersTitle)}</GrandText>
          </HStack>
          <Spacer />
          <Button
            _focus={{
              boxShadow: 'none',
              outline: 'none'
            }}
            _hover={{
              bg: 'transparent'
            }}
            aria-label={overrideText('Rensa all filtrering', overrides?.reset)}
            color="text.default"
            fontSize="sm"
            fontWeight="sm"
            onClick={() => clearFilters()}
            size="xs"
            textDecor="underline"
            variant="ghost">
            {overrideText('Återställ', overrides?.reset)}
          </Button>
        </HStack>
        <GrandAccordion
          allowMultiple
          buttonProps={{
            fontSize: 'md',
            textDecor: 'underline',
            fontWeight: 'medium'
          }}
          items={accordionItems}
          p={0}
          w="full"
        />
      </VStack>

      <VStack align="flex-end" w="full">
        <Button
          aria-label={overrideText('Sök', overrides?.search)}
          bg="primary"
          color="text.light"
          onClick={handleUpdateSearch}
          size="sm"
          w="full"
          {...{ isLoading }}>
          {overrideText('Sök', overrides?.search)}
        </Button>
      </VStack>
    </VStack>
  )
}

export default MoreFilters
