'use client'
import type { FC } from 'react'
import type { RangeSliderProps, StackProps } from '@chakra-ui/react'
import { HStack, VStack } from '@chakra-ui/react'
import { GrandText } from 'ui'
import { overrideText } from 'ui/lib/overrides'
import { useOverridesContext } from 'ui/lib/overrides/hooks'
import RangeFilterItem from './RangeFilterItem'

interface IFilterPriceSelectorProps extends StackProps {
  currentMinValue: string
  currentMaxValue: string
  unitsLabel: string
  rangeProps: RangeSliderProps
  sliderMaxPrice: number
}

export interface FilterRangeSelectorOverrides {
  filterRangeSelectorFrom?: string
  filterRangeSelectorTo?: string
}

const FilterRangeSelector: FC<IFilterPriceSelectorProps> = ({
  currentMinValue,
  currentMaxValue,
  unitsLabel,
  rangeProps,
  sliderMaxPrice,
  ...props
}) => {
  const overrides = useOverridesContext<keyof FilterRangeSelectorOverrides>()
  return (
    <HStack flexGrow={1} p={6} spacing={8} {...props}>
      <VStack flexShrink={0}>
        <GrandText fontSize="xs">{`${overrideText(
          'från',
          overrides?.filterRangeSelectorFrom
        )} ${unitsLabel}`}</GrandText>
        <GrandText fontSize="xs">{currentMinValue}</GrandText>
      </VStack>
      <RangeFilterItem max={sliderMaxPrice} min={0} {...rangeProps} />
      <VStack flexShrink={0}>
        <GrandText fontSize="xs">{`${overrideText(
          'upp till',
          overrides?.filterRangeSelectorTo
        )} ${unitsLabel}`}</GrandText>
        <GrandText fontSize="xs">{currentMaxValue}</GrandText>
      </VStack>
    </HStack>
  )
}

export default FilterRangeSelector
