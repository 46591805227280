'use client'
import type { FC, ReactElement } from 'react'
import type { MenuButtonProps, MenuProps } from '@chakra-ui/react'
import { Box, Flex, HStack, Menu, MenuButton } from '@chakra-ui/react'
import { GrandText } from 'ui'
import { BiChevronDown } from 'react-icons/bi'
import { createCn } from 'shared-utils'
import { clsx } from 'clsx'
import { filterButtonProps } from './product-list/ProductsFilter/filterButtonProps'

interface IFilterMenusProps extends Partial<MenuProps> {
  filterName: string
  list: ReactElement
  menuButtonProps?: Partial<MenuButtonProps>
}

const cn = createCn('filter-menus')

const FilterMenus: FC<IFilterMenusProps> = ({
  filterName,
  list,
  menuButtonProps: { className, ...menuButtonProps } = {},
  ...props
}) => {
  return (
    <Menu {...props}>
      <MenuButton
        as={Box}
        className={clsx(cn(), className)}
        {...filterButtonProps}
        {...menuButtonProps}>
        <HStack align="stretch" spacing={0}>
          <GrandText fontSize="sm">{filterName}</GrandText>
          <Flex align="center">
            <BiChevronDown />
          </Flex>
        </HStack>
      </MenuButton>

      {list}
    </Menu>
  )
}

export default FilterMenus
