import { useGetCachedRequest } from 'api/utils/next'
import type { InitialProductsState } from 'ecosystem'

interface UseInitialProductReqProps {
  endpointPath: string
  id: string
  type: 'category' | 'brand' | 'search' | 'tag'
  queryParams: any
}

const useInitialProductsRequest = ({
  endpointPath,
  id,
  type,
  queryParams
}: UseInitialProductReqProps) => {
  const qp = new URLSearchParams(queryParams).toString()

  return useGetCachedRequest<InitialProductsState>(
    qp ? `${endpointPath}?id=${id}&type=${type}&${qp}` : null,
    'state'
  )
}

export default useInitialProductsRequest
