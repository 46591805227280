'use client'
import type { FC } from 'react'
import type { CheckboxProps } from '@chakra-ui/react'
import { Checkbox, HStack } from '@chakra-ui/react'
import { GrandText } from 'ui'

interface ICheckboxFilterItemProps extends CheckboxProps {
  id: string
  label?: string
}

const CheckboxFilterItem: FC<ICheckboxFilterItemProps> = ({ id, label = '', ...props }) => {
  return (
    <HStack spacing={4} w="100%">
      <Checkbox
        borderColor="primary"
        borderRadius="sm"
        display="flex"
        flex={0.1}
        fontSize={12}
        justifyContent="center"
        size="lg"
        {...{ id }}
        {...props}
      />
      <HStack flex={1} spacing={1}>
        <GrandText fontSize="sm" w="auto">
          {props.value || 'no value'}
        </GrandText>
        <GrandText fontSize="sm" w="auto">
          {label}
        </GrandText>
      </HStack>
    </HStack>
  )
}

export default CheckboxFilterItem
