'use client'
import type { FC } from 'react'
import { useEffect, useState } from 'react'
import { Radio, RadioGroup, Stack } from '@chakra-ui/react'
import type { SortingOption, SortOrderType } from 'ecosystem'

interface IFilterMobileSortingProps {
  sortingOptions: SortingOption[]
  selectedSorting: SortingOption
  onChange: (sortingselection: SortingOption) => void
}

const FilterMobileSorting: FC<IFilterMobileSortingProps> = ({
  sortingOptions,
  onChange,
  selectedSorting
}) => {
  const [selectedSortingOption, setSelectedSortingOption] = useState<SortingOption | null>(
    selectedSorting
  )

  useEffect(() => {
    if (selectedSortingOption) {
      onChange(selectedSortingOption)
    }
  }, [onChange, selectedSortingOption])

  const getOptionValue = (option: SortingOption | null) => `${option?.sortBy}-${option?.sortOrder}`

  return (
    <RadioGroup
      onChange={(value) => {
        if (!value) return
        const [sortBy, sortOrder] = value.split('-')
        const sortLabel =
          sortingOptions.find((o) => o.sortBy === sortBy && o.sortOrder === sortOrder)?.sortLabel ||
          ''
        setSelectedSortingOption({ sortBy, sortOrder: sortOrder as SortOrderType, sortLabel })
      }}
      value={getOptionValue(selectedSorting)}
      w="full">
      <Stack direction="row" justifyContent="flex-start" w="full">
        {sortingOptions.map((option) => {
          const value = getOptionValue(option)
          return (
            <Radio key={option.sortLabel} {...{ value }} colorScheme="gray" size="sm">
              {option.sortLabel}
            </Radio>
          )
        })}
      </Stack>
    </RadioGroup>
  )
}

export default FilterMobileSorting
