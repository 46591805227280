import type { FC } from 'react'
import { MdOutlinePictureAsPdf } from 'react-icons/md'
import { RiFile3Line, RiFileExcel2Line } from 'react-icons/ri'
import type { OldProductDTO } from 'ecosystem'
import { ProductMediaType } from 'ecosystem'
import { overrideText } from 'ui/lib/overrides'
import { useOverridesContext } from 'ui/lib/overrides/hooks'
import { ProductAttachedItem } from './ProductAttachedItem'
import { ProductAttachedItemsWrapper } from './ProductAttachedItemsWrapper'

interface IProductAttachedFilesProps {
  product: OldProductDTO
}

export interface ProductAttachedFilesOverrides {
  productFilesFileLabel?: string
}

const ProductAttachedFiles: FC<IProductAttachedFilesProps> = ({ product }) => {
  const overrides = useOverridesContext<keyof ProductAttachedFilesOverrides>()
  const factory = (fileType: string, iconStyle: { color: string }) => {
    switch (fileType) {
      case 'xlsx':
        return <RiFileExcel2Line size={30} style={iconStyle} />
      case 'xls':
        return <RiFileExcel2Line size={30} style={iconStyle} />
      case 'pdf':
        return <MdOutlinePictureAsPdf size={30} style={iconStyle} />
      default:
        return <RiFile3Line size={30} style={iconStyle} />
    }
  }

  return (
    <ProductAttachedItemsWrapper>
      {product.medias
        ? product.medias.map((media, index) => {
            const iconStyle = { color: 'grey' }
            const subStrings = media.url.split('.')
            const fileType = subStrings[subStrings.length - 1]
            if (media.type === ProductMediaType.DOCUMENT) {
              return (
                <ProductAttachedItem
                  icon={factory(fileType, iconStyle)}
                  // eslint-disable-next-line react/no-array-index-key -- No unique id
                  key={index}
                  name={
                    media.name !== null
                      ? media.name
                      : `${fileType} ${overrideText(
                          'dokumentera',
                          overrides?.productFilesFileLabel
                        )}`
                  }
                  url={media.url}
                />
              )
            }
            return ''
          })
        : null}
    </ProductAttachedItemsWrapper>
  )
}

export default ProductAttachedFiles
