'use client'
import type { FC } from 'react'
import { useEffect, useMemo, useState } from 'react'
import {
  Box,
  Center,
  Flex,
  VStack,
  Wrap,
  WrapItem,
  type BoxProps,
  usePrevious
} from '@chakra-ui/react'
import { TbMovie } from 'react-icons/tb'
import type { ProductMedia } from 'ecosystem'
import { ProductMediaType } from 'ecosystem'
import { badgesFactory, httpImageFactory } from 'api'
import { Carousel as CarouselComponent, ImageBox, VideoBox } from 'ui'
import { useProductContext } from '../hooks/useProductContext'
import ProductBadges from './ProductBadges'

type IProductGalleryProps = BoxProps

const ProductGallery: FC<IProductGalleryProps> = ({ ...props }) => {
  const { product, bundles, badgesOptions, onOpenMedias } = useProductContext()
  const [activeItem, setActiveItem] = useState(0)
  const prevProductId = usePrevious(product.id)

  useEffect(() => {
    if (product.id !== prevProductId) setActiveItem(0)
  }, [prevProductId, product.id])

  const productBadges = badgesFactory(badgesOptions || [], product.tagIds || [])

  const medias = useMemo<ProductMedia[]>(() => {
    let filteredMedias =
      product.medias?.filter(
        (media) => media.type === ProductMediaType.IMAGE || media.type === ProductMediaType.VIDEO
      ) || []
    // eslint-disable-next-line no-nested-ternary -- Doesn't harm if ternary here
    filteredMedias = [...filteredMedias].sort((a, b) => (a.main ? -1 : b.main ? 1 : 0))
    if (bundles?.length) {
      const newMedias = [...filteredMedias]
      bundles.forEach((bundledProduct) => {
        if (bundledProduct.medias?.length) {
          const mainMedia = bundledProduct.medias.find(
            (media) =>
              media.main &&
              (media.type === ProductMediaType.IMAGE || media.type === ProductMediaType.VIDEO)
          )
          if (mainMedia) newMedias.push(mainMedia)
        }
      })
      return newMedias
    }
    return filteredMedias
  }, [bundles, product.medias])

  const handleOnImageClick = (mediaIndex: number) => {
    medias.length && onOpenMedias(medias, mediaIndex)
  }

  return (
    <VStack className="ProductGallery" h="auto" mx="auto" {...props}>
      <Flex
        className="ProductGallerySlider"
        alignItems="stretch"
        justifyContent="stretch"
        minH={{ base: '50vh', md: '60vh' }}
        w="100%"
        h="100%">
        {medias.length ? (
          <CarouselComponent
            carouselItemsByBreakpoint={{ sm: 1, md: 1, lg: 1 }}
            containerProps={{
              display: 'flex',
              flexDirection: 'column',
              w: '100%'
            }}
            gap={20}
            showItemCount
            {...{ activeItem, setActiveItem }}>
            {medias.map((item, index) => (
              <Box
                h="100%"
                // eslint-disable-next-line react/no-array-index-key -- No ID available
                key={index}
                w="full"
                minH="200px"
                display="flex"
                justifyContent="center"
                alignItems="center">
                {item.type === ProductMediaType.IMAGE ? (
                  <ImageBox
                    badges={
                      <ProductBadges
                        badges={productBadges}
                        display={medias.length && medias[index]?.main ? 'initial' : 'none'}
                        imageProps={{
                          sizes: `(min-width: 40em) 120px,
                            (min-width: 30em) 100px,
                            80px
                          `
                        }}
                        scalableSize={[80, 100, 120]}
                      />
                    }
                    cursor="pointer"
                    w="100%"
                    h="100%"
                    minHeight="200px"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    imageProps={{
                      alt: product.name || '',
                      title: product.name,
                      src: httpImageFactory(item.url),
                      style: {
                        objectFit: 'contain'
                      },
                      sizes: '(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 50vw'
                    }}
                    onClick={() => handleOnImageClick(index)}
                    p={0}
                    withZoomIcon
                  />
                ) : (
                  <VideoBox playback={activeItem === index} source={item.url} w="full" />
                )}
              </Box>
            ))}
          </CarouselComponent>
        ) : (
          <Box
            h="full"
            w="full"
            minH="200px"
            display="flex"
            justifyContent="center"
            alignItems="center">
            <ImageBox
              h={{
                base: '30vh',
                md: '100%'
              }}
              minHeight="200px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              imageProps={{
                alt: product.name,
                title: product.name,
                src: '',
                sizes: '(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 50vw',
                style: {
                  objectFit: 'contain'
                }
              }}
              p={0}
            />
          </Box>
        )}
      </Flex>

      <Wrap display={{ base: 'none', md: 'initial' }} justify="center" spacing={4} w="100%">
        {medias.map((item, index) => {
          const itemProps = {
            w: 12,
            h: 12,
            p: 2,
            borderColor: activeItem === index ? 'primary' : 'initial',
            borderRadius: 'sm',
            borderWidth: activeItem === index ? '2px' : '1.2px',
            onClick: () => setActiveItem(index),
            cursor: 'pointer',
            transition: 'all 0.1s ease-in',
            _hover: {
              opacity: 0.75
            }
          }
          return (
            // eslint-disable-next-line react/no-array-index-key -- No ID available
            <WrapItem key={`productGallery-${index}`}>
              {item.type === ProductMediaType.IMAGE ? (
                <ImageBox
                  height="48px"
                  imageProps={{
                    alt: product.name,
                    title: product.name,
                    src: httpImageFactory(item.url),
                    sizes: '48px',
                    quality: 30,
                    style: {
                      objectFit: 'contain'
                    }
                  }}
                  width="48px"
                  minHeight="48px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  {...itemProps}
                />
              ) : (
                <Center {...itemProps}>
                  <TbMovie size="20px" />
                </Center>
              )}
            </WrapItem>
          )
        })}
      </Wrap>
    </VStack>
  )
}

export default ProductGallery
