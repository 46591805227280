import type { IngridCart, IngridCartItem, OldProductDTO } from 'ecosystem'
import { getProductImages } from 'api'
import { v4 as uuidv4 } from 'uuid'
import { sumArrayOfNumbers } from 'shared-utils'

export const loadScript = (
  src: string,
  options: {
    onLoad: GlobalEventHandlers['onload'] | null
  }
) => {
  const script = document.createElement('script')
  script.src = src
  /**
   * important: note the type attribute below
   */
  script.type = 'module'
  script.onload = options.onLoad
  document.body.appendChild(script)
}

export const generateIngridItemCartFromProduct = (
  product: OldProductDTO,
  quantity = 1,
  totalPrice?: number
): IngridCartItem => {
  const { mainImageUrl } = getProductImages(product.medias)
  const attributes = [`delivery_id:${product.deliveryId}`]
  const ingridAttr = product.specifications?.find((spec) => spec.name.toLowerCase() === 'ingrid')

  if (ingridAttr?.value) {
    attributes.push(ingridAttr?.value)
  }

  return {
    name: product.name,
    price: (totalPrice ?? product.price) * 100 * quantity,
    discount: 0,
    quantity,
    sku: product.id,
    attributes,
    image_url: mainImageUrl
  }
}

export const generateIngridCart = (cartItems: IngridCartItem[]): IngridCart => {
  return {
    cart_id: uuidv4(),
    items: cartItems,
    total_value: sumArrayOfNumbers(cartItems.map((item) => item.price)),
    total_discount: sumArrayOfNumbers(cartItems.map((item) => item.discount))
  }
}
