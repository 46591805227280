'use client'
import type { FC } from 'react'
import { Button, Stack } from '@chakra-ui/react'
import { useRouter } from 'next/navigation'
import { BiChevronLeft } from 'react-icons/bi'
import { GrandSection } from 'ui'
import { overrideText } from 'ui/lib/overrides'
import { useOverridesContext } from 'ui/lib/overrides/hooks'
import { useProductContext } from '../../hooks/useProductContext'
import type { ProductInfoWrapperOverrides } from './types'

export interface IProductInfoWrapperProps {
  children: any
}

const ProductInfoWrapper: FC<IProductInfoWrapperProps> = ({ children }) => {
  const overrides = useOverridesContext<keyof ProductInfoWrapperOverrides>()
  const {
    windowState: { prevLocation }
  } = useProductContext()
  const router = useRouter()
  return (
    <GrandSection
      className="ProductInfoWrapper"
      px={{ base: 0, md: 10 }}
      sectionId="product-detail">
      <Stack
        align={{
          base: 'flex-start',
          lg: 'stretch'
        }}
        direction={{
          base: 'column',
          lg: 'row'
        }}
        justify="space-around"
        position="relative"
        spacing={{ base: 0, lg: 8 }}
        w="full">
        {children}
        <Button
          aria-label={overrideText('föregående sida', overrides?.previousPageBtnLabel)}
          bg="transparent"
          color="text.mild"
          fontWeight={400}
          left={0}
          onClick={() => router.back()}
          position="absolute"
          top={-10}
          variant="plain"
          {...(prevLocation === '' && { display: 'none' })}>
          {' '}
          <BiChevronLeft size={20} />{' '}
          <span>{overrideText('föregående sida', overrides?.previousPageBtnLabel)}</span>
        </Button>
      </Stack>
    </GrandSection>
  )
}

export default ProductInfoWrapper
