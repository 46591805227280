import type { FC } from 'react'
import { useMemo } from 'react'
import { Heading, VStack } from '@chakra-ui/react'
import type { ProductMedia } from 'ecosystem'
import { FiExternalLink } from 'react-icons/fi'
import lodashGroupBy from 'lodash/groupBy'
import { ProductAttachedItemsWrapper } from './ProductAttachedItemsWrapper'
import { ProductAttachedItem } from './ProductAttachedItem'

interface ProductAttachedLinksProps {
  medias: ProductMedia[]
}

interface TProductLinksGroup {
  groupName: string
  items: ProductMedia[]
}

export const ProductAttachedLinks: FC<ProductAttachedLinksProps> = ({ medias }) => {
  const { ungroupedLinks, groups } = useMemo(() => {
    const groupsDict = lodashGroupBy(medias, 'groupBy')

    let _ungroupedLinks: ProductMedia[] = []

    if (groupsDict.null) {
      _ungroupedLinks = groupsDict.null
      delete groupsDict.null
    }

    const _groups: TProductLinksGroup[] = Object.keys(groupsDict).map((key) => ({
      groupName: key,
      items: groupsDict[key]
    }))

    return {
      ungroupedLinks: _ungroupedLinks,
      groups: _groups
    }
  }, [medias])

  return (
    <VStack alignItems="stretch" gap={6} w="100%">
      {!!ungroupedLinks.length && (
        <ProductAttachedItemsWrapper>
          {ungroupedLinks.map((media) => (
            <ProductAttachedItem
              icon={<FiExternalLink size={26} />}
              key={`${media.name}${media.url}`}
              name={media.name}
              url={media.url}
            />
          ))}
        </ProductAttachedItemsWrapper>
      )}

      {!!groups.length && (
        <VStack alignItems="stretch" gap={6}>
          {groups.map(({ groupName, items }) => (
            <VStack alignItems="stretch" gap={4} key={groupName}>
              <Heading as="div" size="sm">
                {groupName}
              </Heading>

              <ProductAttachedItemsWrapper>
                {items.map((media) => (
                  <ProductAttachedItem
                    icon={<FiExternalLink size={26} />}
                    key={`${media.name}${media.url}`}
                    name={media.name}
                    url={media.url}
                  />
                ))}
              </ProductAttachedItemsWrapper>
            </VStack>
          ))}
        </VStack>
      )}
    </VStack>
  )
}
