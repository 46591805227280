'use client'
import type { FC } from 'react'
import { useEffect, useState } from 'react'
import type { ListItemProps } from '@chakra-ui/react'
import { ListItem, StackDivider, UnorderedList, VStack } from '@chakra-ui/react'
import type { SortingOption } from 'ecosystem'

interface IFilterSortingProps {
  selectedSorting: SortingOption
  onChange: (sortingselection: SortingOption) => void
  sortingOptions: SortingOption[]
}

const FilterSorting: FC<IFilterSortingProps> = ({ onChange, selectedSorting, sortingOptions }) => {
  const [selectedSortingOption, setSelectedSortingOption] = useState<SortingOption | null>(
    selectedSorting
  )

  useEffect(() => {
    if (selectedSortingOption) {
      onChange(selectedSortingOption)
    }
  }, [onChange, selectedSortingOption])

  return (
    <VStack divider={<StackDivider />} p={4}>
      <UnorderedList spacing={2} w="full">
        {sortingOptions.map((option) => {
          const isSelected =
            option.sortOrder === selectedSorting.sortOrder &&
            option.sortBy === selectedSorting.sortBy

          return (
            <SortingItem
              key={option.sortLabel}
              onClick={() => {
                setSelectedSortingOption(option)
              }}
              {...{ isSelected }}>
              {option.sortLabel}
            </SortingItem>
          )
        })}
      </UnorderedList>
    </VStack>
  )
}

export default FilterSorting

interface SortingItemProps extends ListItemProps {
  isSelected: boolean
}

const SortingItem: FC<SortingItemProps> = ({ isSelected, children, ...props }) => (
  <ListItem
    color={isSelected ? 'primary' : 'text.default'}
    cursor="pointer"
    fontWeight={isSelected ? 'black' : 'regular'}
    listStyleType="none"
    textDecoration={isSelected ? 'underline' : 'none'}
    textTransform="capitalize"
    {...props}>
    {children}
  </ListItem>
)
