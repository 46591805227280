import dynamic from 'next/dynamic'

const ConfigurationProductInformation = dynamic(
  async () => import('./ConfigurationProductInformation'),
  {
    ssr: true
  }
)

const ConfigurableProduct = () => {
  return <ConfigurationProductInformation />
}

export default ConfigurableProduct
