'use client'
import type { FC } from 'react'
import { useMemo } from 'react'
import type { StackProps } from '@chakra-ui/react'
import { HStack, Tooltip } from '@chakra-ui/react'
import type { TagLightDTO } from 'ecosystem'
import type { IImageBoxProps } from 'ui'
import { ImageBox } from 'ui'

interface IProductBadgesProps extends StackProps {
  badges: TagLightDTO[]
  badgeProps?: IImageBoxProps
  imageProps?: IImageBoxProps['imageProps']
  preventScaling?: boolean
  scalableSize?: number[] //value in pixels
  scalableH?: number[] //value in pixels
  scalableW?: number[] //value in pixels
}

const ProductBadges: FC<IProductBadgesProps> = ({
  badges,
  badgeProps,
  imageProps,
  preventScaling = false,
  scalableSize,
  scalableH,
  scalableW,
  ...props
}) => {
  const scalableSizes: {
    h: (string | number)[]
    w: (string | number)[]
  } = useMemo(() => {
    let h = [45, 50, 64]
    let w = [45, 50, 64]
    if (scalableSize?.length) {
      h = scalableSize
      w = scalableSize
    }
    if (scalableH?.length) h = scalableH
    if (scalableW?.length) w = scalableW

    if (preventScaling || !badges.length) return { h, w }
    const scaleToListSize = (value: number) => {
      if (isNaN(value) || value <= 0) return 0
      // we expect a list of max 3 badges.
      const listSize = badges.length ? badges.length - 1 : 0
      // reduce by 10% increments depending on list size
      const scaleFactor = 1 - listSize / 10
      return Math.round(value * scaleFactor)
    }
    return {
      h: h.map((heightValue) => `${scaleToListSize(heightValue)}px`),
      w: w.map((widthValue) => `${scaleToListSize(widthValue)}px`)
    }
  }, [preventScaling, badges.length, scalableSize, scalableH, scalableW])

  return (
    <HStack flexWrap="wrap" {...props}>
      {badges.map((badge) => (
        <Tooltip key={badge.id}>
          <ImageBox
            bg={undefined}
            h={scalableSizes.h}
            imageProps={{
              src: badge.imgUrl,
              alt: badge.name,
              sizes: `(min-width: 40em 64px, 
                (min-width: 30em 50px,
                45px
              `,
              quality: 90,
              style: {
                objectFit: 'contain'
              },
              ...imageProps
            }}
            w={scalableSizes.w}
            {...badgeProps}
          />
        </Tooltip>
      ))}
    </HStack>
  )
}

export default ProductBadges
