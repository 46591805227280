'use client'
import type { FC, ReactNode } from 'react'
import { Button, type ButtonProps, Flex, HStack } from '@chakra-ui/react'
import { GrandIconButton, type GrandIconButtonProps } from 'ui'
import { BiShoppingBag } from 'react-icons/bi'
import { TbListDetails } from 'react-icons/tb'
import { useRouter } from 'next/navigation'
import type { DeliveryDTO, Option, ProductLightDTO, ProductPrice } from 'ecosystem'
import { ProductType } from 'ecosystem'
import { deliveryFactory } from 'api'
import { overrideText } from 'ui/lib/overrides'
import { useOverridesContext } from 'ui/lib/overrides/hooks'
import { createCn } from 'shared-utils'
import Price from './Price'

interface ProductListItemBuyProps {
  product: ProductLightDTO
  price: ProductPrice | null
  deliveryOptions: Option<DeliveryDTO[]>
  productsUrlPath: string
  onBuy: (item: ProductLightDTO) => void
  onProductRequest?: (item: ProductLightDTO) => void
  buttonProps?: ButtonProps
  priceRequestButtonProps?: ButtonProps
}

export interface ProductListItemBuyOverrides {
  productRequest?: string
  addToCart?: string
  productListItemBuyToDetails?: string
}

const cn = createCn('product-list-item-buy')

const ProductListItemBuy: FC<ProductListItemBuyProps> = ({
  onBuy,
  product,
  price,
  deliveryOptions,
  onProductRequest,
  productsUrlPath,
  priceRequestButtonProps: { onClick: priceRequestButtonOnClick, ...priceRequestButtonProps } = {},
  buttonProps: { onClick, ...buttonProps } = {}
}) => {
  const hasPriceRequest = product.priceRequest
  const delivery = deliveryFactory(deliveryOptions, product.deliveryId)
  const router = useRouter()
  const overrides = useOverridesContext<keyof ProductListItemBuyOverrides>()

  if (hasPriceRequest) {
    return (
      <Flex justify="center" w="full" className={cn('price-request')}>
        <Button
          className={cn('price-request-button')}
          aria-label={overrideText('Offertförfrågan', overrides?.productRequest)}
          onClick={(e) => {
            onProductRequest?.(product)
            priceRequestButtonOnClick?.(e)
          }}
          size="sm"
          {...priceRequestButtonProps}>
          {overrideText('Offertförfrågan', overrides?.productRequest)}
        </Button>
      </Flex>
    )
  }

  switch (product.type) {
    case ProductType.REGULAR: {
      return (
        <Wrapper>
          {price ? (
            <Price className={cn('price')} align="center" orientation="vertical" {...price} />
          ) : null}
          <HStack className={cn('button-wrapper')} bottom="20%" position="absolute" right="5%">
            <ProductListItemBuyButton
              aria-label={overrideText('Lägg till i varukorg', overrides?.addToCart)}
              icon={<BiShoppingBag />}
              isDisabled={!price || !delivery?.buyable}
              onClick={(e) => {
                onBuy(product)
                onClick?.(e)
              }}
              tooltip={overrideText('Lägg till i varukorg', overrides?.addToCart)}
              {...buttonProps}>
              {overrideText('Lägg till i varukorg', overrides?.addToCart)}
            </ProductListItemBuyButton>
          </HStack>
        </Wrapper>
      )
    }

    case ProductType.BUNDLE: {
      return (
        <Wrapper>
          {price ? (
            <Price className={cn('price')} align="center" orientation="vertical" {...price} />
          ) : null}
          <HStack className={cn('button-wrapper')} bottom="20%" position="absolute" right="5%">
            <ProductListItemBuyButton
              aria-label="Gå till detaljer"
              icon={<TbListDetails />}
              isDisabled={!price || !delivery?.buyable}
              onClick={(e) => {
                router.push(`${productsUrlPath}/${product.slug}`)
                onClick?.(e)
              }}
              tooltip={overrideText('Gå till detaljer', overrides?.productListItemBuyToDetails)}
              {...buttonProps}>
              {overrideText('Gå till detaljer', overrides?.productListItemBuyToDetails)}
            </ProductListItemBuyButton>
          </HStack>
        </Wrapper>
      )
    }

    case ProductType.CONFIGURATION: {
      return (
        <Wrapper>
          {price ? (
            <Price
              className={cn('price')}
              align="center"
              orientation="vertical"
              prefix="Från"
              {...price}
            />
          ) : null}
          <HStack className={cn('button-wrapper')} bottom="20%" position="absolute" right="5%">
            <ProductListItemBuyButton
              aria-label="Gå till detaljer"
              icon={<TbListDetails />}
              isDisabled={!price || !delivery?.buyable}
              onClick={(e) => {
                router.push(`${productsUrlPath}/${product.slug}`)
                onClick?.(e)
              }}
              tooltip={overrideText('Gå till detaljer', overrides?.productListItemBuyToDetails)}
              {...buttonProps}>
              {overrideText('Gå till detaljer', overrides?.productListItemBuyToDetails)}
            </ProductListItemBuyButton>
          </HStack>
        </Wrapper>
      )
    }

    default:
      return null
  }
}

export default ProductListItemBuy

function Wrapper({ children }: { children?: ReactNode | ReactNode[] }) {
  return (
    <HStack justify="space-between" position="relative" px="16px" w="100%" className={cn()}>
      {children}
    </HStack>
  )
}

function ProductListItemBuyButton(props: GrandIconButtonProps) {
  return (
    <GrandIconButton
      className={cn('button')}
      bg="primary"
      color="text.light"
      minW={undefined}
      shadow="none"
      size="sm"
      {...props}
    />
  )
}
