import type { FC } from 'react'
import type { ButtonProps } from '@chakra-ui/react'
import { Button } from '@chakra-ui/react'

type IProductDetailAddToCartButtonProps = ButtonProps

const ProductDetailAddToCartButton: FC<IProductDetailAddToCartButtonProps> = ({
  children,
  ...props
}) => {
  return (
    <Button h="3rem" p="12px 24px" w="100%" {...props}>
      {children}
    </Button>
  )
}

export default ProductDetailAddToCartButton
