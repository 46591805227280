'use client'
import type { FC } from 'react'
import type { IGrandHeadingProps } from 'ui'
import { GrandHeading } from 'ui'

interface IProductTitleProps extends IGrandHeadingProps {
  title: string
}

const ProductTitle: FC<IProductTitleProps> = ({ title, ...props }) => {
  return (
    <GrandHeading
      fontSize={['2xl', '3xl']}
      fontWeight="medium"
      headingTag="h1"
      textAlign={{ base: 'left' }}
      textTransform="uppercase"
      title={title}
      {...props}
    />
  )
}

export default ProductTitle
