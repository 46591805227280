'use client'
import type { FC } from 'react'
import type { NumberInputProps } from '@chakra-ui/react'
import { NumberSelector } from 'ui'

type IProductDetailAddToCartCounterProps = NumberInputProps

const ProductDetailAddToCartCounter: FC<IProductDetailAddToCartCounterProps> = ({ ...props }) => {
  return (
    <NumberSelector
      formControlProps={{ w: 'auto' }}
      size="lg"
      {...props}
      hiddenLabel="kvantitet"
      id="product-qty"
    />
  )
}

export default ProductDetailAddToCartCounter
