'use client'
import type { ReactElement } from 'react'
import { Box } from '@chakra-ui/react'
import { IoMdConstruct } from 'react-icons/io'
import { EmptyMessage, GrandAccordion, GrandSection, GrandTabs, ParsedHtml } from 'ui'
import { overrideText } from 'ui/lib/overrides'
import { useOverridesContext } from 'ui/lib/overrides/hooks'
import { ProductMediaType } from 'ecosystem'
import { useProductContext } from '../hooks/useProductContext'
import ProductSpecification from './ProductSpecification'
import { ProductAttachedFilesAndLinks } from './ProductAttachedFilesAndLinks'

export interface ProductTabsOverrides {
  productTabsDescriptionTitle?: string
  productTabsSpecificationTitle?: string
  productTabsAttachedFilesTitle?: string
  productTabsNoDescription?: string
  productTabsReviewsTitle?: string
}

const ProductTabs = () => {
  const { product } = useProductContext()
  const overrides = useOverridesContext<keyof ProductTabsOverrides>()

  const productTabs = [
    {
      label: overrideText('Produktbeskrivning', overrides?.productTabsDescriptionTitle),
      component: (
        <Box>
          <ParsedHtml
            html={
              product.description ||
              overrideText('Ingen produktbeskrivning', overrides?.productTabsNoDescription)
            }
          />
        </Box>
      ),
      isAvailable: true
    },
    {
      label: overrideText('Produktspecifikationer', overrides?.productTabsSpecificationTitle),
      component: <ProductSpecification {...{ product }} />,
      isAvailable: true
    },
    {
      label: overrideText('Produktrecensioner', overrides?.productTabsReviewsTitle),
      component: (
        <EmptyMessage icon={<IoMdConstruct size={80} />} text="Reviews under construction" />
      ),
      isAvailable: false
    },
    {
      label: overrideText('Bifogade filer', overrides?.productTabsAttachedFilesTitle),
      component: <ProductAttachedFilesAndLinks {...{ product }} />,
      isAvailable: !!product.medias?.some((media) =>
        [ProductMediaType.DOCUMENT, ProductMediaType.LINK].includes(media.type)
      )
    }
  ]

  return (
    <GrandSection pt={{ base: '0', md: '24px' }} sectionId="product-description">
      <GrandTabs
        display={{ base: 'none', md: 'flex' }}
        tabProps={{
          fontSize: { base: 'xs', lg: 'md' },
          whiteSpace: 'nowrap',
          color: 'text.darkGrey'
        }}
        tabs={productTabs}
        w="full"
      />
      <GrandAccordion
        allowMultiple
        buttonProps={{
          color: 'text.mild'
        }}
        display={{ base: 'initial', md: 'none' }}
        items={productTabs.map(({ label, component }, index) => ({
          id: String(index),
          title: label,
          component: component as ReactElement
        }))}
        panelProps={{
          fontSize: { base: 'xs', md: 'md' }
        }}
        w="full"
      />
    </GrandSection>
  )
}

export default ProductTabs
