'use client'
import { useCallback, useEffect, useMemo, useState } from 'react'
import useInterval from 'ui/hooks/useInterval'
import useDidUpdateEffect from 'ui/hooks/useDidUpdateEffect'
import type { CartItem, CartState, DiscountDTO, Language, Option } from 'ecosystem'
import { sumPropValues } from 'shared-utils'
import { useDispatch, useSelector } from 'shared-redux'
import {
  addToCart,
  applyDiscountCode,
  applyDiscountToCart,
  cleanCart,
  removeFromCart,
  selectedCart,
  setCartLocale,
  sumCart
} from 'shared-redux/state'
import { usePathname } from 'next/navigation'
import { CART_COUNTDOWN } from '../constants'

export type UseCartHook = CartState & {
  handleAddToCart: (cartItem: CartItem) => void
  handleRemoveFromCart: (cartItem: CartItem) => void
  handleApplyDiscountCode: (discount: DiscountDTO) => void
  discountedCartItems: Option<CartItem[]>
  cartTotalDiscount: Option<number>
  cartCount: number
  isCartOpen: boolean
}

interface CartOpts {
  locale?: Language
  onAddToCart?: (cartItem: CartItem, cartTotal: number) => void
  onRemoveFromCart?: (cartItem: CartItem, cartTotal: number) => void
}

const useStdCart = (opts?: CartOpts): UseCartHook => {
  const dispatch = useDispatch()
  const pathname = usePathname()
  const state = useSelector(selectedCart)

  // ****** Localization -- If applies *******

  useEffect(() => {
    if (!opts?.locale) return
    if (!state.cartLocale || state.cartLocale !== opts?.locale) {
      dispatch(cleanCart())
      dispatch(setCartLocale(opts.locale))
    }
  }, [state.cartLocale, dispatch, opts?.locale])

  // ******* LOGIC TO OPEN THE CART WITH COUNTDOWN  *****
  const cartCount = sumPropValues(state.cartItems, 'qty')
  const [isCartOpen, setIsCartOpen] = useState(false)
  const [count, setCount] = useState<number>(0)

  useEffect(() => {
    if (count === CART_COUNTDOWN) {
      setIsCartOpen(false)
    }
  }, [count])

  useInterval(
    () => {
      setCount(count + 1)
    },
    isCartOpen ? 1000 : null
  )

  useDidUpdateEffect(() => {
    if (cartCount && pathname !== '/checkout') {
      setIsCartOpen(true)
      setCount(0)
    }
  }, [cartCount])

  const handleAddToCart = useCallback(
    (cartItem: CartItem) => {
      dispatch(addToCart(cartItem))
      opts?.onAddToCart?.(cartItem, state.cartTotal)
    },
    [dispatch, opts, state.cartTotal]
  )
  const handleRemoveFromCart = useCallback(
    (cartItem: CartItem) => {
      dispatch(removeFromCart(cartItem))
      opts?.onRemoveFromCart?.(cartItem, state.cartTotal)
    },
    [dispatch, opts, state.cartTotal]
  )

  const handleApplyDiscountCode = useCallback(
    (code: DiscountDTO) => {
      dispatch(applyDiscountCode(code))
    },
    [dispatch]
  )

  const discountedCartItems = useMemo(() => {
    const discountCode = state.cartDiscountCode

    if (discountCode) {
      return applyDiscountToCart(state.cartItems, discountCode)
    }
    return null
  }, [state.cartDiscountCode, state.cartItems])

  const cartTotalDiscount = useMemo(() => {
    if (discountedCartItems) {
      return state.cartTotal - sumCart(discountedCartItems)
    }
    return null
  }, [discountedCartItems, state.cartTotal])

  return {
    handleAddToCart,
    handleRemoveFromCart,
    handleApplyDiscountCode,
    discountedCartItems,
    cartTotalDiscount,
    ...state,
    cartCount,
    isCartOpen
  }
}

export default useStdCart
