'use client'
import type { FC } from 'react'
import { useMemo } from 'react'
import type { CartItem } from 'ecosystem'
import { ProductType, RelatedProductTypes } from 'ecosystem'
import { deliveryFactory } from 'api'
import { Box, VStack, Stack } from '@chakra-ui/react'
import { useSelector } from 'shared-redux'
import { selectedCartItems } from 'shared-redux/state'
import dynamic from 'next/dynamic'
import type { OverridesContextType } from 'ui/lib/overrides'
import { OverridesContextProvider } from 'ui/lib/overrides'
import type { ProductContextType } from '../context'
import { ProductContext } from '../context'
import RegularProduct from './_product-types/RegularProduct'
import ConfigurationProduct from './_product-types/ConfigurationProduct'
import FeaturedProducts from './FeaturedProducts'
import ProductGallery from './ProductGallery'
import ProductTabs from './ProductTabs'

const ProductInfoWrapper = dynamic(() => import('./ProductInfoWrapper/ProductInfoWrapper'), {
  ssr: true
})

export type ProductDetailPageStdConfig = Omit<
  ProductContextType,
  'productDelivery' | 'relatedProductGroups' | 'onAddToCart' | 'cartItems'
> & {
  onAddToCart: (cartItem: CartItem) => void
  overrides?: OverridesContextType
}

interface ProductDetailPageStdProps {
  config: ProductDetailPageStdConfig
}

const ProductDetailPageStd: FC<ProductDetailPageStdProps> = ({ config }) => {
  const cartItems = useSelector(selectedCartItems)
  const productDelivery = deliveryFactory(config.deliveryOptions, config.product.deliveryId)
  const productComponent = useMemo(() => {
    switch (String(config.product.type) as ProductType) {
      case ProductType.REGULAR:
      case ProductType.BUNDLE:
        return <RegularProduct />
      case ProductType.CONFIGURATION:
        return <ConfigurationProduct />
      default:
        return <>Invalid product type</>
    }
  }, [config.product.type])

  const featuredProducts = useMemo(() => {
    const related = config.relatedGroups[RelatedProductTypes.STANDARD]

    if (related?.length) {
      return related.map((e) => e.product)
    }
    return []
  }, [config.relatedGroups])

  return (
    <OverridesContextProvider overrides={{ ...config.overrides }}>
      <ProductContext.Provider value={{ ...config, cartItems, productDelivery }}>
        <VStack w="full">
          <>
            <ProductInfoWrapper>
              <Stack direction={{ base: 'column', lg: 'row' }} w="full" gap={8} align="start">
                <ProductGallery minWidth="0" flex={1} w="full" />
                <Box
                  w="full"
                  flex={{
                    base: 1,
                    lg: 0.5
                  }}>
                  {productComponent}
                </Box>
              </Stack>
            </ProductInfoWrapper>
            <ProductTabs />
          </>

          <FeaturedProducts
            deliveryOptions={config.deliveryOptions}
            items={featuredProducts}
            onBuy={config.onAddToCart}
            onProductRequest={config.onProductRequest}
            productsUrlPath={config.paths.productsUrlPath}
            sectionId="std-related-products"
          />
        </VStack>
      </ProductContext.Provider>
    </OverridesContextProvider>
  )
}

export default ProductDetailPageStd
