import type { OldProductDTO, ProductMedia } from 'ecosystem'
import { ProductMediaType } from 'ecosystem'
import { useMemo } from 'react'
import { VStack } from '@chakra-ui/react'
import ProductAttachedFiles from './ProductAttachedFiles'
import { ProductAttachedLinks } from './ProductAttachedLinks'

interface ProductAttachedFilesAndLinksProps {
  product: OldProductDTO
}

export const ProductAttachedFilesAndLinks = ({ product }: ProductAttachedFilesAndLinksProps) => {
  const { documents, links } = useMemo(() => {
    return (product.medias || []).reduce<{ documents: ProductMedia[]; links: ProductMedia[] }>(
      (acc, media) => {
        if (media.type === ProductMediaType.DOCUMENT) {
          acc.documents.push(media)
        } else if (media.type === ProductMediaType.LINK) {
          acc.links.push(media)
        }
        return acc
      },
      { documents: [], links: [] }
    )
  }, [product])

  return (
    <VStack alignItems="stretch" gap={6}>
      {!!documents.length && <ProductAttachedFiles product={product} />}

      {!!links.length && <ProductAttachedLinks medias={links} />}
    </VStack>
  )
}
