import { useStdCart } from 'storefront-modules/cart'
import { useCallback } from 'react'
import { useGoogleAnalytics } from 'ui/lib/third-party'

import { useSelector } from 'shared-redux'
import { CartItem } from 'ecosystem'
import { selectedCart } from 'shared-redux/state'

export const useCustomCart = () => {
  const GA = useGoogleAnalytics()
  const state = useSelector(selectedCart)

  const onAddToCart = useCallback(
    (cartItem: CartItem) => {
      GA.updateCart('add', state.cartTotal, [
        {
          ...cartItem,
          qty: String(cartItem.qty || 1)
        }
      ])
    },
    [GA, state.cartTotal]
  )

  const onRemoveFromCart = useCallback(
    (cartItem: CartItem) => {
      GA.updateCart('remove', state.cartTotal, [cartItem])
    },
    [GA, state.cartTotal]
  )

  return useStdCart({ onAddToCart, onRemoveFromCart })
}
