export const filterButtonProps = {
  minH: '32px',
  cursor: 'pointer',
  bg: 'background.surface',
  px: 4,
  py: 1.5,
  borderRadius: 'sm',
  fontWeight: 'bold',
  fontSize: 'sm',
  color: 'text.default',
  size: 'sm'
}
