'use client'
import type { FC } from 'react'
import { GrandText } from 'ui'
import type { TextProps } from '@chakra-ui/react'
import { overrideText } from 'ui/lib/overrides'
import { useOverridesContext } from 'ui/lib/overrides/hooks'

interface IProductDetailDeliveryLabelProps extends TextProps {
  label?: string
}

export interface ProductDetailDeliveryLabelOverrides {
  productDetailDeliveryLabelUnavailable?: string
}

const ProductDetailDeliveryLabel: FC<IProductDetailDeliveryLabelProps> = ({ label, ...props }) => {
  const overrides = useOverridesContext<keyof ProductDetailDeliveryLabelOverrides>()
  return (
    <GrandText align="left" fontSize="sm" fontWeight="bold" {...props}>
      {label || overrideText('Inte tillgänglig', overrides?.productDetailDeliveryLabelUnavailable)}
    </GrandText>
  )
}

export default ProductDetailDeliveryLabel
